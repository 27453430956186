import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BxiLogo from '../../assets/BXI_LOGO.png';
import whitearr from '../../assets/whitearr.png';
import Arrow from '../../assets/LoginArrow.svg';
import DrawerComp from './DrawerComp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const [imgArr, setImgArr] = useState(false);
  const currentLocation = window.location.pathname;

  const handleToast = () => {
    navigate('/login');
  };

  const pages = [
    { name: 'Home', link: '/', text: '/' },
    { name: 'Learn Barter', link: '/learnbarter', text: '/learnbarter' },
    { name: 'About', link: '/about', text: '/about' },
    { name: 'Marketplace', link: '/marketplace', text: '/marketplace' },
  ];

  return (
    <React.Fragment>
      <ToastContainer style={{ fontSize: '16px' }} />
      <AppBar
        position="fixed"
        sx={{
          width: '100%',
          background: '#fff',
          boxShadow: '0px 8px 16px rgba(220, 220, 220, 0.5)',
        }}
      >
        <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'end',
              flexDirection: 'row',
              gap: '8px',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          >
            <img src={BxiLogo} alt="" width="45px" />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                textAlign: 'center',
                color: '#000',
                mb: '-4px',
                textTransform: 'uppercase',
              }}
            >
              Barter Exchange of India
            </Typography>
          </Box>

          {isMatch ? (
            <DrawerComp />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
              >
                {pages.map((page, index) => (
                  <Button
                    key={index}
                    sx={{
                      ...NavbarText,
                      color: '#000',
                      textTransform: 'none',
                      m: '0 5px',
                      p: '6px 16px',
                      minHeight: '48px',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '3px',
                        backgroundColor: '#375DBB',
                        transform:
                          currentLocation === page.text
                            ? 'scaleX(1)'
                            : 'scaleX(0)',
                        transformOrigin: 'bottom left',
                        transition: 'transform 0.3s ease-in-out',
                      },
                      '&:hover': {
                        backgroundColor: 'transparent',
                        '&::after': {
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => navigate(page.link)}
                    disableRipple
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>

              <Button
                sx={{
                  ...NavbarText,
                  borderRadius: '17px',
                  border: '1px solid #252525',
                  padding: '12px',
                  minWidth: '180px',
                  height: '42px',
                  textAlign: 'center',
                  ml: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition:
                    'background-color 0.3s, border-color 0.3s, color 0.3s',
                  '&:hover': {
                    backgroundColor: '#2261A2',
                    borderColor: '#2261A2',
                    color: '#fff',
                  },
                }}
                onMouseEnter={() => setImgArr(true)}
                onMouseLeave={() => setImgArr(false)}
                onClick={handleToast}
                disableRipple
              >
                <span style={{ marginRight: '8px' }}>Login / Sign Up</span>
                <Box
                  component="img"
                  src={imgArr ? whitearr : Arrow}
                  alt="Arrow"
                  sx={{
                    height: 'auto',
                    width: '30px',
                  }}
                />
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;

const NavbarText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '18px',
  color: '#252525',
  cursor: 'pointer',
};
